.mirora_product_action > p {margin-top: -1rem !important;}
.send {margin-top: -2rem !important;}

/* HOMEPAGE */
.per{
  position: absolute;
  bottom: 20px;
  left: 5px;
}

.body{ margin-top: 5vh; }
.left{ text-align: left; }
.right{ text-align: right; }
.center{ text-align: center; }

.head{border: 1px solid #a8741a !important;}
.head b{color: #a8741a;}
.golden{color: #a8741a;}
.stock{position: absolute; bottom: -2px; right: 0; height: 120px;}

/* .list-img{ height: 250px; width: 250px; }
@media screen and (max-width: 1200px)  { .list-img { height: 200px;  width: 200px; } }
@media screen and (max-width: 576px)  { .list-img { height: 300px;  width: 300px; } } */

.logout{ font-size: 40px; }
.no-nav{ position: absolute; top: 0; z-index: 9999; }

.homecheck{font-size: large;}
.description{padding-right: 20px;padding-left: 20px;}
.float-log{ position: absolute; top: 0%; right: 5%; transform: translate(-50%, -50%); z-index: 9999; }
/* .blur{ box-shadow: 0px 0px 300px 100px black } */

.float-msg{ position: fixed; top: 5%; left: 50%; transform: translate(-50%, -50%); z-index: 9;  }
.message{ animation: msgColor 1000ms linear infinite; animation-delay: 300ms; user-select: none }
@keyframes msgColor { from { color: black; } to { color: #a8741a; } }

.link{ text-decoration: none; text-decoration-color: none; color: white; }
.list-img{ height: 250px; width: 250px; }
@media screen and (max-width: 1200px)  { .list-img { height: 200px;  width: 200px; } }
@media screen and (max-width: 576px)  { .list-img { height: 300px;  width: 300px; } }


/* Product Detail Page */
.title:hover{color: #a8741a;}
.check{font-size: x-large;}
.price{font-size: x-large; padding: 0% 3%;}
.youtube{position: absolute; margin-left: -30px; margin-top: -42px;}

.tag{text-align: left;}
.icons{font-size: small;}
@media screen and (max-width: 1000px) { .icons{font-size: x-small;} }
@media screen and (max-width: 576px) { .icons{font-size: small;} .tag{text-align: center;} }

.product-details-img img{height: 420px; width: 420px !important;}
.tmi2 img{height: 120px; width: 120px}
@media screen and (max-width: 1199px) { .product-details-img img{height: 350px; width: 350px !important;} .tmi2 img{height: 100px;} }
@media screen and (max-width: 800px) { .product-details-img img{height: 250px; width: 250px !important;} .tmi2 img{height: 80px; width: 80px;} }
@media screen and (max-width: 576px) { .product-details-img img{height: 400px; width: 400px !important;} .tmi2 img{height: 110px; width: 110px;} }
@media screen and (max-width: 500px) { .product-details-img img{height: 80vw; width: 100vw !important;} .tmi2 img{height: 24vw; width: 30vw;} }
@media screen and (max-width: 400px) { .product-details-img img{height: 90vw; width: 100vw !important;} .tmi2 img{height: 27vw; width: 30vw;} }
@media screen and (max-width: 300px) { .product-details-img img{height: 100vw; width: 100vw !important;} .tmi2 img{height: 30vw; width: 30vw;} }

/* zoomin image slider */
.zoom{height: 45vw;width: 45vw;}
@media screen and (max-width: 1200px) { .zoom{height: 50vw; width: 50vw;} .close{left: 45vw} }
@media screen and (max-width:  576px) { .zoom{height: 0vw; width: 0vw;} .close{left: 43vw; top: 7%;} }
/* Previous/Next */
.navigate{position: absolute; top: 45%; transform: translate(-50%, -50%); z-Index: 7; font-size: xxx-large}
.previous{left: 21%; padding: 120px 120px 50px 0px; user-select: none}
.next{left: 40%; padding: 120px 20px 50px 120px; user-select: none}
@media screen and (max-width: 1400px) { .previous{left: 15%} .next{left: 38%} .navigate{top: 42%;} }
@media screen and (max-width: 1200px) { .previous{left: 20%} }
@media screen and (max-width: 1100px) { .previous{left: 18%} }
@media screen and (max-width: 1000px) { .previous{left: 20%} .next{left: 42%} }
@media screen and (max-width:  900px) { .previous{left: 15%} }
@media screen and (max-width:  800px) { .previous{left: 10%;padding: 120px 80px 50px 0px;} .next{padding: 120px 20px 50px 80px;} .navigate{top: 42%;} }
@media screen and (max-width:  770px) { .previous{left: 20%} .next{left: 44%} .navigate{top:35%} }
@media screen and (max-width:  700px) { .previous{left: 18%} }
@media screen and (max-width:  650px) { .previous{left: 14%} }
@media screen and (max-width:  576px) { .previous{left: 20%;padding: 100px 120px 100px 0px;} .next{left: 78%; padding: 100px 20px 100px 120px;} .navigate{top: 25%;} }

/* admin panel */
.preview{height: 150px;width: 150px;}
@media screen and (max-width:  1000px) { .preview{height: 100px;width: 100px;} }
@media screen and (max-width:  576px) { .preview{height: 50px;width: 50px;} }


/* Product Zoom Big Panel */

/* .tab-pane img{height: 400px;}
.tmi1 img{height: 120px; width: 120px}
@media screen and (max-width: 1399px) { .tab-pane img{height: 200px;} .tmi1 img{height: 50px;} }
@media screen and (max-width: 768px)  { .tab-pane img{height: 400px;} .tmi1 img{height: 100px;} }
@media screen and (max-width: 500px)  { .tab-pane img{height: 350px;} .tmi1 img{height: 80px;} }
@media screen and (max-width: 400px)  { .tab-pane img{height: 250px;} .tmi1 img{height: 70px;} }
@media screen and (max-width: 300px)  { .tab-pane img{height: 200px;} .tmi1 img{height: 60px;} }
@media screen and (max-width: 200px)  { .tab-pane img{height: 150px;} .tmi1 img{height: 50px;} } */


/* .per{position: absolute; font-size: small;} */
/* .hover:hover{box-shadow: 0px 0px 20px 5px white;} */

/* ZOOM */
/* .zoom{ height: 600px; width: 1200px; padding: 25px; background-color: #252525; box-shadow: 0px 0px 300px 100px black }
.parent{display: flex;}
.pointer{ cursor: pointer; }

.max{ height: 380px; width: 380px;}
.mini{ height: 115px; width: 115px;}

@media screen and (max-width: 1300px) { 
  .zoom{ height: 520px; width: 1000px; }
  .max{ height: 330px; width: 330px; }
  .mini{ height: 98px; width: 98px; }
}

@media screen and (max-width: 1100px) { 
  .zoom{ height: 480px; width: 750px; }
  .max{ height: 290px; width: 290px; }
  .mini{ height: 84px; width: 84px; }
}

@media screen and (max-width: 800px)  {
  .zoom{ height: 380px; width: 600px; }
  .max{ height: 220px; width: 220px; }
  .mini{ height: 61px; width: 61px; }
}

@media screen and (max-width: 700px)  {
  .zoom{ height: 350px; width: 500px; }
  .max{ height: 170px; width: 170px; }
  .mini{ height: 46px; width: 46px; }
}

@media screen and (max-width: 576px)  { .zoom{ height: 600px; width: 450px; } }
@media screen and (max-width: 500px)  { .zoom{ width: 400px; } }
@media screen and (max-width: 450px)  { .zoom{ width: 350px; } }
@media screen and (max-width: 400px)  { .zoom{ width: 300px; } }
@media screen and (max-width: 350px)  { .zoom{ width: 300px; } }
@media screen and (max-width: 320px)  { .zoom{ width: 280px; } }



/* NAVBAR */
.fixed{
  position:fixed;
  top:0;
  width:100%;
}

.darknav {
  background-color: #060b26;
  padding-top: 0;
  height:67px;
  position:fixed;
  z-index: 8;
  top:0;
  width:100%;
}

.menu-bars {
  margin-left: 1.5rem;
  font-size: 2rem;
  background: none;
}

.bars{
  margin-top: 12px;
}

.button{
  position:fixed;
  z-index: 9;
  top: -2px;;
  right: 8px;
}

.nav-menu {
  background-color: #060b26;
  width: 275px;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.scroll{
  overflow-y: auto;
}

.no-scroll{
  width: 260px;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  padding: 8px 0px 8px 10px;
  height: 60px;
}

.nav-text a {
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 10px;
}

.nav-text a:hover {
  background-color: #0275d8;
}

.navbar-toggle {
  background-color: #060b26;
  height:67px;
  display: flex;
  align-items: center;
}

span {
  margin-left: 15px;
}

/* detail */
.items{
  margin: 1% 1% 3% 1%;
  padding-top: 25px;
  /* margin: 1% 3% 3% 3%; */
}
.list{
  padding-left: 7%;
}
.stripped{
  background-color: #ebedef;
}
.filter-btn{
  width: 120px;
}

@media screen and (max-width: 576px) {
  .list-img {
      height: 160px;
  }

  .product-img-overlay {
      height: 105% !important;
  }

  .product-img{
      height: 160px;
  }
}